<template>
    <div class="box" v-loading="isBoxLoading">
        <div class="video" v-if="videoList.length > 0">
            <div class="list" :default-active="videoId + 'v'" style="background-color: #004C6E;">
                <el-menu background-color="#004C6E" text-color="#fff" active-text-color="#ffd04b"
                    class="el-menu-vertical-demo">
                    <el-menu-item v-for="item in videoList" :index="item.pkVideoId + 'v'" :key="item.pkVideoId"
                        @click="changeVideo(item)">
                        {{ item.videoNum }}
                    </el-menu-item>
                </el-menu>
            </div>
            <div class="view">
                <Video v-if="isShow" :videoId="videoId" :url="url" :videoType="videoType" />
                <el-empty v-else description="该视频无调流链接"></el-empty>
            </div>
        </div>
        <el-empty v-else description="该视频无调流链接"></el-empty>
    </div>
</template>

<script>
import Video from '@/components/Video'
import { findVideoByTask, } from '@/api/Video'
export default {
    data() {
        return {
            videoId: null,
            url: null,
            videoType: null,
            videoNum: null,
            videoList: [],
            isShow: false,
            isBoxLoading: true,

        };
    },

    components: { Video },
    methods: {
        async findVideoByTaskFun() {
            const { data: res } = await findVideoByTask(this.$route.params.projectId)
            this.videoList = res.data.list
            this.videoId = this.videoList[0].pkVideoId
            this.isBoxLoading = false
            this.changeVideo(this.videoList[0])

        },
        changeVideo(row) {

            if (row.url) {
                this.videoId = row.pkVideoId
                this.url = row.url
                this.videoType = row.videoType
                this.isShow = true
            } else {
                this.isShow = false
            }



        }
    },
    created() {

    },
    mounted() {
        this.findVideoByTaskFun()
    },
};
</script>

<style scoped lang="scss">
.box {
    width: 100%;
    height: 100%;
}

.video {
    width: 100%;
    height: 100%;
    display: flex;

    .list {
        width: 200px;
        border-top: 1px solid #003D58;

        .el-menu {
            border-right: none;
        }

        .el-menu-item {
            border-bottom: 1px solid #003D58;
        }
    }

    .view {
        // padding: 5px;
        // width: calc(100% - 220px);
        // height: calc(100% - 20px);
        flex: 1;
        height: 100%;
        overflow: hidden;
    }


}
</style>

